/** @jsx jsx */
import React, { useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import callingCodes from '../constants/callingCodes';
import useTheme from '../hooks/useTheme';
import AttributedText from './attributedText';
import useLanguage from '../hooks/useLanguage';

interface IPhoneInputProps {
  countryCode?: string;
  phoneNumber?: string;
  countryCodeSelectLabel?: string;
  phoneInputLabel?: string;
  label?: string;
  autoFocus?: boolean;

  activeLineColor?: string;
  inactiveLineColor?: string;

  returnKeyType?: string;

  onChange?: (countryCode: string, phoneNumber: string) => void;
  onReturnKeyPress?: () => void;
  onBlur?: () => void;
}

const PhoneInput = ({
  countryCode = '',
  phoneNumber = '',
  countryCodeSelectLabel,
  phoneInputLabel,
  label,
  autoFocus,
  activeLineColor,
  inactiveLineColor,
  returnKeyType,
  onChange,
  onReturnKeyPress,
  onBlur,
  ...props
}: IPhoneInputProps) => {
  const selectRef = useRef<HTMLSelectElement | null>();
  const [isFocused, setIsFocused] = useState(false);
  const { color } = useTheme();
  const language = useLanguage();

  const INPUT_STYLE: CSSObject = {
    fontSize: 17,
    backgroundColor: color.LOCAL_WHITE,
    padding: '6px 0 8px',
    height: '26px',
    lineHeight: '26px',
    borderRadius: 0,
    borderStyle: 'none none solid',
    borderBottom: `2px solid ${inactiveLineColor || color.UI_IDLE}`,
    ':focus': {
      borderBottom: `2px solid ${activeLineColor || color.PRIMARY}`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },
  };

  const LABEL_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <label css={LABEL_STYLE} {...props}>
      {/* Component-wide label */}
      {label && <AttributedText text={label} />}

      <div css={{ display: 'flex', position: 'relative' }}>
        {/* Country code display */}
        <div
          translate='no'
          css={{
            ...INPUT_STYLE,
            borderBottom: `2px solid ${
              isFocused
                ? activeLineColor || color.PRIMARY
                : inactiveLineColor || color.UI_IDLE
            }`,
            textAlign: 'right',
            color: color.TEXT,
            position: 'absolute',
            marginRight: 8,
            minWidth: 55,
          }}
          onClick={() => {
            selectRef.current?.focus();
          }}
        >
          {`+${countryCode}`}
        </div>

        {/* Country code select behind the scenes */}
        <select
          id='countryCodeSelect'
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={(ref) => (selectRef.current = ref)}
          css={{ opacity: 0, width: 55, marginRight: 8 }}
          value={countryCode}
          onChange={(e) => {
            if (onChange) {
              onChange(e.currentTarget.value, phoneNumber);
            }
          }}
          aria-label={
            countryCodeSelectLabel || language.get('select_country_code')
          }
        >
          {callingCodes.map((item, index) => {
            return (
              <option
                key={index}
                value={item.code}
              >{`${item.countryName} +${item.code} `}</option>
            );
          })}
        </select>

        {/* phone number input */}
        <input
          id='phoneNumberInput'
          css={{ ...INPUT_STYLE, width: '100%' }}
          placeholder={phoneInputLabel || language.get('phone_number')}
          autoFocus={autoFocus}
          type='tel'
          pattern='\d*'
          value={phoneNumber}
          onChange={(e) => {
            if (onChange) {
              onChange(countryCode, e.currentTarget.value);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onReturnKeyPress) {
              onReturnKeyPress();
            }
          }}
          onBlur={onBlur}
          aria-label={phoneInputLabel || language.get('phone_number')}
          // @TODO: enterKeyHint is not supported in React 16, force the property until we update.
          {...{
            enterkeyhint: returnKeyType,
          }}
          {...props}
        />
      </div>
    </label>
  );
};

export default PhoneInput;
