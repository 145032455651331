import { partnerBackgrounds, partnerLogos } from './assets';
import environment, { ICountry } from '../utils/environment';
import { IColors } from '../hooks/useTheme';
import { setCurrentPartnerId } from '../utils/request';

interface ITheme {
  headerBackgroundColor?: string;
  headerTextColor?: string;
  headerKryLiviLogoTint?: keyof typeof IColors;
  colors?: Partial<Record<keyof typeof IColors, string>>;
}

interface IPartner {
  id: string;
  basePath: string;
  logo?: string;
  backgroundImage?: string;
  hideKryLiviLogoSection?: boolean;
  theme?: ITheme;
}

const partners: Record<ICountry, Array<IPartner>> = {
  SE: [
    {
      id: 'light-test',
      basePath: '/partner/light-test',
      logo: partnerLogos.TESTPARTNER_LIGHT,

      theme: {
        headerBackgroundColor: '#644AFF',
        headerTextColor: 'rgba(255, 255, 255, 0.6)',
        headerKryLiviLogoTint: 'LOCAL_WHITE',
        colors: {
          PRIMARY: '#644AFF',
          PRIMARY_PRESS: '#3420A9',
          SECONDARY: '#F0EDFF',
          SECONDARY_PRESS: '#C1B7FF',
        },
      },
    },
    {
      id: 'test',
      basePath: '/partner/test',
      logo: partnerLogos.TESTPARTNER,
    },
    {
      id: 'vgr',
      basePath: '/partner/vgr',
      logo: partnerLogos.VGR,
    },
  ],

  NO: [
    {
      id: 'test',
      basePath: '/partner/test',
      logo: partnerLogos.TESTPARTNER,
    },
  ],

  GB: [
    {
      id: 'lime',
      basePath: '/partner/embeddable/lime',
      hideKryLiviLogoSection: true,
      theme: {
        colors: { EMBEDDABLE_PARTNER_BG: '#AED3B0' },
      },
    },
  ],

  FR: [
    {
      id: 'healthpoint',
      basePath: '/partner/telemedica',
      logo: partnerLogos.TELEMEDICA,
    },
    {
      id: 'mon-bilan-cardio',
      basePath: '/partner/mon-bilan-cardio',
      logo: partnerLogos.MALAKOFF,
    },
    {
      id: 'april',
      basePath: '/partner/april',
      logo: partnerLogos.APRIL,
    },
    {
      id: 'benefiz',
      basePath: '/partner/benefiz',
      logo: partnerLogos.BENEFIZ,
      backgroundImage: partnerBackgrounds.BENEFIZ,
    },
    {
      id: 'jetransporte',
      basePath: '/partner/jetransporte',
      logo: partnerLogos.JETRANSPORTE,
      backgroundImage: partnerBackgrounds.JETRANSPORTE,
    },
    {
      id: 'elsan',
      basePath: '/partner/elsan',
      logo: partnerLogos.ELSAN,
      backgroundImage: partnerBackgrounds.ELSAN,
      hideKryLiviLogoSection: true,

      theme: {
        headerBackgroundColor: '#FFF2DE',
        colors: {
          PRIMARY: '#2D7799',
          PRIMARY_PRESS: '#002845',
          SECONDARY: '#C7E1ED',
          SECONDARY_PRESS: '#78B2CC',
        },
      },
    },
    {
      id: 'sidecare',
      basePath: '/partner/sidecare',
      logo: partnerLogos.SIDECARE,
      backgroundImage: partnerBackgrounds.SIDECARE,
      theme: {
        colors: {
          PRIMARY: '#6D092A',
          PRIMARY_PRESS: '#870B34',
          SECONDARY: '#FFF8EB',
          SECONDARY_PRESS: '#FFE8BE',
        },
      },
    },
    {
      id: 'aglae',
      basePath: '/partner/aglae',
      logo: partnerLogos.AGLAE,
    },
    {
      id: 'prevoir',
      basePath: '/partner/prevoir',
      logo: partnerLogos.PREVOIR,
    },
    {
      id: 'mindday',
      basePath: '/partner/mindday',
      logo: partnerLogos.MINDDAY,
    },
  ],
};

// TODO: Check how does partners currently work.
const currentPartner = partners[environment.COUNTRY].find((partner) =>
  window.location.pathname.startsWith(partner.basePath)
);

// Set the global custom partner header.
if (currentPartner) {
  setCurrentPartnerId(currentPartner.id);
}

export default currentPartner;
