import Cookies from 'js-cookie';
import environment from './environment';

export const setSessionCookie = (sessionId: string) => {
  // The xsrf cookie set by the backend.
  const xsrfToken = Cookies.get('XSRF-TOKEN-sessionid');

  if (environment.IS_DEV) {
    // The sessionid cookie cannot be set automatically when running locally
    // due to it having the Secure attribute so we set it manually.
    Cookies.set('sessionid', sessionId, {
      domain: environment.DOMAIN,
      sameSite: 'none',
      secure: true,
    });
  } else {
    // When running in production or on stage/test, the sessionid and XSRF-TOKEN-sessionid
    // cookies don't have a domain property, meaning they will get the same domain as the client is running from.
    // In order to be able to do requests to other subdomains, we manually set those cookies for the the entire domain.
    Cookies.set('sessionid', sessionId, {
      domain: environment.DOMAIN,
      sameSite: 'none',
      secure: true,
    });

    if (xsrfToken) {
      Cookies.set('XSRF-TOKEN-sessionid', xsrfToken, {
        domain: environment.DOMAIN,
        sameSite: 'none',
        secure: true,
      });
    }
  }
};

export const removeSessionCookie = () => {
  // Attempt to delete the manually set cookies. Note that the automatically set sessionid cookie
  // has the Secure attribute and cannot be manually deleted.
  Cookies.remove('sessionid');
  Cookies.remove('sessionid', { domain: environment.DOMAIN });
  Cookies.remove('XSRF-TOKEN-sessionid');
  Cookies.remove('XSRF-TOKEN-sessionid', { domain: environment.DOMAIN });
};
